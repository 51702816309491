import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TokenData {
  openMarkerInfoTab: boolean;
  selectedMarkerInfo: MarkersTypes;
  selectedMarkerGroup: MarkersTypes[];
  clickedButton: string;
}

const initialState: TokenData = {
  openMarkerInfoTab: false,
  selectedMarkerInfo: null,
  selectedMarkerGroup: [],
  clickedButton: null,
};

const markerSlice = createSlice({
  name: "marker",
  initialState,
  reducers: {
    setOpenMarkerInfoTab: (state, action: PayloadAction<boolean>) => {
      state.openMarkerInfoTab = action.payload;
    },
    setSelectedMarkerInfo: (state, action: PayloadAction<MarkersTypes>) => {
      state.selectedMarkerInfo = action.payload;
    },
    setSelectedMarkerGroup: (state, action: PayloadAction<MarkersTypes[]>) => {
      state.selectedMarkerGroup = action.payload;
    },
    setClickedButton: (state, action: PayloadAction<string>) => {
      state.clickedButton = action.payload;
    },
  },
});

export const { setOpenMarkerInfoTab, setSelectedMarkerInfo, setSelectedMarkerGroup, setClickedButton } =
  markerSlice.actions;

export default markerSlice.reducer;
