import { configureStore, combineReducers } from "@reduxjs/toolkit";

import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import localforage from "localforage";

import authReducer from "app/features/auth/authSlice";
import chatGPTReducer from "app/features/chatGPT/chatGPTSlice";
import languageReducer from "app/features/language/languageSlice";
import toggleReducer from "app/features/toggle/toggleSlice";
import rftReducer from "app/features/rft/rftSlice";
import tokenReducer from "app/features/token/tokenSlice";
import markerReducer from "app/features/marker/markerSlice";

const rootReducer = combineReducers({
  authReducer,
  chatGPTReducer,
  languageReducer,
  toggleReducer,
  rftReducer,
  tokenReducer,
  markerReducer,
});

const persistConfig: PersistConfig<RootState> = {
  key: "FTP",
  storage: localforage,
  version: 1.39,
  stateReconciler: autoMergeLevel2,
  blacklist: ["toggleReducer", "tokenReducer", "markerReducer"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
