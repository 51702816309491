import { Dialog, TransitionChild, Transition, DialogTitle, DialogPanel } from "@headlessui/react";
import { cn } from "core/helpers";
import { FC, Fragment, MouseEventHandler, ReactNode } from "react";
import { HiOutlineX } from "react-icons/hi";

interface ModalInterface {
  showModal: boolean;
  closeModal: () => void;
  closeButtonClick: MouseEventHandler<HTMLButtonElement>;
  title: string;
  modalStyle: string;
  titleStyle: string;
  showCloseButton: boolean;
  showTitle: boolean;
  disableCloseButton: boolean;
  children: ReactNode;
}

const Modal: FC<Partial<ModalInterface>> = ({
  showModal,
  closeModal = () => {},
  children,
  closeButtonClick,
  title,
  modalStyle,
  titleStyle,
  showCloseButton = true,
  showTitle = true,
  disableCloseButton,
}) => {
  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-[100] overflow-y-auto" onClose={() => {}} onClick={closeModal}>
        <div className="min-h-screen px-2 sm:px-4 text-center">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/40" aria-hidden="true" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={cn(
                "inline-block w-full my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl p-2 sm:px-4 sm:py-3 border text-darkCharcoal font-normal font-plusJakarta text-xs sm:text-sm",
                modalStyle
              )}
            >
              {(showTitle || showCloseButton) && (
                <div className={`flex items-center justify-between pb-1 ${showTitle ? "border-b" : ""}`}>
                  {showTitle && (
                    <DialogTitle
                      as="h3"
                      className={`font-londrinaSolid text-base font-light tracking-wider sm:text-xl ${titleStyle}`}
                    >
                      {title}
                    </DialogTitle>
                  )}

                  {showCloseButton && (
                    <button
                      disabled={disableCloseButton}
                      className="ml-auto inline-flex items-center rounded-md bg-gray-200 p-1 sm:p-1.5 outline-none transition duration-300 ease-in-out hover:bg-gray-200 hover:text-darkCharcoal disabled:pointer-events-none lg:bg-transparent lg:text-gray-400"
                      data-modal-toggle="walletModal"
                      onClick={closeButtonClick}
                    >
                      <HiOutlineX className="sm:h-5 sm:w-5 h-4 w-4" />
                    </button>
                  )}
                </div>
              )}
              <DialogPanel>{children}</DialogPanel>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
